import { shorterApi } from ".";

export const linkApi = shorterApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLinks: builder.query({
      query: () => ({
        url: "/links",
        method: "GET",
      }),
      providesTags: ["MappingLinks"],
    }),

    getLinkByShortlink: builder.query({
      query: (shortlink) => ({
        url: "/link",
        method: "GET",
        params: { shortlink: shortlink },
      }),
    }),

    getDestination: builder.query({
      query: (shortedlink) => ({
        url: `/open`,
        method: "GET",
        params: { shortlink: shortedlink },
      }),
    }),

    createNewLink: builder.mutation({
      query: (dto) => ({
        url: "/create",
        method: "POST",
        body: dto,
      }),
      invalidatesTags: ["MappingLinks"],
    }),

    deleteLink: builder.mutation({
      query: (dto) => ({
        url: "/delete",
        method: "DELETE",
        params: dto,
      }),
      invalidatesTags: ["MappingLinks"],
    }),

    updateLink: builder.mutation({
      query: (dto) => ({
        url: "/update",
        method: "PUT",
        body: dto,
      }),
      invalidatesTags: ["MappingLinks"],
    }),


  }),
});

export const {
  useLazyGetLinksQuery,
  useGetDestinationQuery,
  useGetLinksQuery,
  useCreateNewLinkMutation,
  useDeleteLinkMutation,
  useUpdateLinkMutation,
  useGetLinkByShortlinkQuery
} = linkApi;
