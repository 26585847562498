import React, { useState } from "react";
import './index.css'
import Delete from "../../../components/icons/Delete";
import Copy from "../../../components/icons/Copy";
import { Editor } from "@monaco-editor/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

function ConverterType() {
  const [value, setValue] = useState("");
  const [output, setOutput] = useState("");


  function jsonStringToTypeScript(jsonString, interfaceName = 'Root') {
    let json;
    try {
      json = JSON.parse(jsonString);
    } catch (error) {
      throw new Error('Invalid JSON string');
    }
  
    const getTypeScriptType = (value, parentInterfaceName, generatedInterfaces) => {
      if (value === null) return 'null';
      const type = typeof value;
  
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const elementType = getTypeScriptType(value[0], parentInterfaceName, generatedInterfaces);
          return `${elementType}[]`;
        }
        return 'any[]';
      }
  
      if (type === 'object') {
        const keys = Object.keys(value);
        if (keys.length === 0) return '{}';
  
        const newInterfaceName = capitalizeFirstLetter(parentInterfaceName); // Custom name for nested interfaces
  
        if (!generatedInterfaces[newInterfaceName]) {
          let interfaceContent = '';
          keys.forEach(key => {
            const keyType = getTypeScriptType(value[key], newInterfaceName, generatedInterfaces);
            interfaceContent += `  ${key}: ${keyType};\n`;
          });
  
          // Create and store the interface definition for this object
          generatedInterfaces[newInterfaceName] = `export interface ${newInterfaceName} {\n${interfaceContent}}`;
        }
  
        return newInterfaceName;
      }
  
      switch (type) {
        case 'string':
          return 'string';
        case 'number':
          return 'number';
        case 'boolean':
          return 'boolean';
        case 'undefined':
          return 'any';  // undefined can be treated as any
        default:
          return 'any';  // Fallback to any type
      }
    };
  
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
  
    let result = `export interface ${interfaceName} {\n`;
    const generatedInterfaces = {}; // Track generated interfaces to avoid duplicates
    
    // Process the root object
    for (const key in json) {
      const type = getTypeScriptType(json[key], interfaceName, generatedInterfaces);
      result += `  ${key}: ${type};\n`;
    }
  
    result += '}';
  
    // Combine the root interface and any nested interfaces
    const allInterfaces = [result, ...Object.values(generatedInterfaces)];
  
    return allInterfaces.join('\n\n');
  }
  

  const handleSubmit = async () => {
    const res = jsonStringToTypeScript(value)
    setOutput(res)
  };

  const copyToClipBoard = () => toast.success(`Copied`);

  return (
    <main className='appConverter'>
      <header className='header__container'>
        <div className='headerConverter'>
          <h3>JSON</h3>
          <div className='header__right'>
            <button className='runBtn' onClick={handleSubmit}>
              RUN
            </button>
            <Delete setValue={setValue} />
          </div>
        </div>

        <div className='headerConverter'>
          <h3>Typescript</h3>
          <CopyToClipboard text={output} onCopy={copyToClipBoard}>
            <span>
              <Copy />
            </span>
          </CopyToClipboard>
        </div>
      </header>

      <div className='code__container'>
        <div className='code'>
          <Editor
            height='90vh'
            className='editor'
            defaultLanguage='json'
            defaultValue='{ }'
            value={value}
            onChange={(value) => setValue(value)}
          />
        </div>
        <div className='output'>

          <Editor
            height='90vh'
            className='editor'
            defaultLanguage='typescript'
            options={{
              domReadOnly: true,
              readOnly: true,
            }}
            defaultValue=''
            value={output}
            onChange={(value) => setOutput(value)}
          />

        </div>
      </div>
    </main>
  );
}

export default ConverterType;
